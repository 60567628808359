// search
export const SEARCH_SET = "SEARCH_SET";
export const SEARCH_SET_FORM_AIRPORTS = "SEARCH_SET_FORM_AIRPORTS";

export const FLIGHT_SEARCH_SUCCESS = "FLIGHT_SEARCH_SUCCESS";
export const FLIGHT_SEARCH_FAIL = "FLIGHT_SEARCH_FAIL";
export const FLIGHT_TOGGLE_SERVICE_INFO = "FLIGHT_TOGGLE_SERVICE_INFO";

export const SEARCH_FLIGHT_START = "SEARCH_FLIGHT_START"; // deprecated
export const SEARCH_FLIGHT_SUCCESS = "SEARCH_FLIGHT_SUCCESS";
export const SEARCH_FLIGHT_FAIL = "SEARCH_FLIGHT_FAIL";
export const SEARCH_SET_TRANSPORT_COMPANIES = "SEARCH_SET_TRANSPORT_COMPANIES";
export const SEARCH_SET_SEARCH_FLIGHTS = "SEARCH_SET_SEARCH_FLIGHTS";
export const SEARCH_REMOVE_SEARCH_FLIGHT = "SEARCH_REMOVE_SEARCH_FLIGHT";
export const SEARCH_ADD_SEARCH_FLIGHT = "SEARCH_ADD_SEARCH_FLIGHT";
export const SEARCH_UPDATE_SEARCH_FLIGHT_ORIGIN =
  "SEARCH_UPDATE_SEARCH_FLIGHT_ORIGIN";
export const SEARCH_UPDATE_SEARCH_FLIGHT_DESTINATION =
  "SEARCH_UPDATE_SEARCH_FLIGHT_DESTINATION";
export const SEARCH_UPDATE_SEARCH_FLIGHT_DEPARTURE_DATE =
  "SEARCH_UPDATE_SEARCH_FLIGHT_DEPARTURE_DATE";
export const SEARCH_UPDATE_SEARCH_FLIGHT_RETURN_DATE =
  "SEARCH_UPDATE_SEARCH_FLIGHT_RETURN_DATE";
export const SEARCH_SET_AIRPORTS = "SEARCH_SET_AIRPORTS";
export const SEARCH_SET_ACTUAL_FLIGHT_SERVICES =
  "SEARCH_SET_ACTUAL_FLIGHT_SERVICES";
export const DELETE_ACTUAL_FLIGHT_SERVICES = "DELETE_ACTUAL_FLIGHT_SERVICES";
export const SEARCH_SET_AIRPLANES = "SEARCH_SET_AIRPLANES";
export const SEARCH_SET_FILTERS_DATA = "SEARCH_SET_FILTERS_DATA";
export const SEARCH_SET_POLICIES = "SEARCH_SET_POLICIES";

// system
export const SET_DEFAULT_AIRPORT_SUGGESTIONS =
  "SET_DEFAULT_AIRPORT_SUGGESTIONS";
export const ERROR_AIRPORT_SUGGESTIONS = "ERROR_AIRPORT_SUGGESTIONS";
export const ADD_AIRPORT_DESTINATIONS = "ADD_AIRPORT_DESTINATIONS";
export const ERROR_INIT_CLIENT = "ERROR_INIT_CLIENT";
export const SET_REQUESTOR_SETTINGS = "SET_REQUESTOR_SETTINGS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_QUERY = "SET_QUERY";
export const SET_AIRLINES = "SET_AIRLINES";
export const SET_PASSENGER_PREFIXES = "SET_PASSENGER_PREFIXES";
export const SET_CALENDAR_SETTINGS = "SET_CALENDAR_SETTINGS";
export const SET_LOYALTY_PROGRAMS = "SET_LOYALTY_PROGRAMS";
export const SET_FRONTEND_SETTINGS = "SET_FRONTEND_SETTINGS";
export const SET_RAW_DEFAULT_AIRPORTS = "SET_RAW_DEFAULT_AIRPORTS";

// booking
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAILED = "BOOKING_FAILED";
export const BOOKING_INIT = "BOOKING_INIT";

// loading
export const START_INITIAL_LOADING = "START_INITIAL_LOADING";
export const STOP_INITIAL_LOADING = "STOP_INITIAL_LOADING";
export const START_UNIVERSAL_LOADING = "START_UNIVERSAL_LOADING";
export const STOP_UNIVERSAL_LOADING = "STOP_UNIVERSAL_LOADING";
export const START_SEARCH_LOADING = "START_SEARCH_LOADING";
export const STOP_SEARCH_LOADING = "STOP_SEARCH_LOADING";
export const ENABLE_INTERNET_CONNECTION = "ENABLE_INTERNET_CONNECTION";
export const DISABLE_INTERNET_CONNECTION = "DISABLE_INTERNET_CONNECTION";

// mock requestor
export const SET_REQUESTOR_DATA = "SET_REQUESTOR_DATA";

// requestorConfig
export const SET_GENERATED_HTML_TIME = "SET_GENERATED_HTML_TIME";
export const SET_REQUESTOR_POSTFIX = "SET_REQUESTOR_POSTFIX";
export const SET_REQUESTOR_CONFIG = "SET_REQUESTOR_CONFIG"; // universal

// mobileForms
export const SET_MOBILE_FORMS_VALUE = "SET_MOBILE_FORMS_VALUE";
export const REMOVE_MOBILE_FORMS_VALUE = "REMOVE_MOBILE_FORMS_VALUE";
export const SET_MOBILE_FORMS_VALUES_FROM_USER_DETAIL =
  "SET_MOBILE_FORMS_VALUES_FROM_USER_DETAIL";

// forms
export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const REMOVE_FORM_VALUE = "REMOVE_FORM_VALUE";
export const FORM_FLIP_FORMBOX_STATE = "FORM_FLIP_FORMBOX_STATE";
export const INIT_FORM_STATE = "INIT_FORM_STATE";

// booking form new
export const SET_BOOKING_FORM_VALUE = "SET_BOOKING_FORM_VALUE";
export const SET_BOOKING_FORM_FIELD_VALUE = "SET_BOOKING_FORM_FIELD_VALUE";
export const SET_BOOKING_FORM_FIELD_VALUE_ONLY =
  "SET_BOOKING_FORM_FIELD_VALUE_ONLY";
export const SET_BOOKING_FORM_FROM_ACQUISITION_PARAMS =
  "SET_BOOKING_FORM_FROM_ACQUISITION_PARAMS";
export const SET_BOOKING_FORM_PARAMS = "SET_BOOKING_FORM_PARAMS";
export const SET_BOOKING_FORM_MARKETPLACE_FROM_ACQUISITION_PARAMS =
  "SET_BOOKING_FORM_MARKETPLACE_FROM_ACQUISITION_PARAMS";
export const SET_BOOKING_FETCHING_SERVICES = "SET_BOOKING_FETCHING_SERVICES";
export const SET_BOOKING_FORM_ELEMENTS_REFS = "SET_BOOKING_FORM_ELEMENTS_REFS";

// user
export const SET_USER_VALUE = "SET_USER_VALUE";
export const SET_USER_VALUES = "SET_USER_VALUES";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOAD_USER_RESERVATIONS_SUCCESS = "LOAD_USER_RESERVATIONS_SUCCESS";

// CBT
export const CBT_USERS_FETCHED = "CBT_USERS_FETCHED";
export const CBT_MANAGERS_FETCHED = "CBT_MANAGERS_FETCHED";
export const CBT_COST_CENTERS_FETCHED = "CBT_COST_CENTERS_FETCHED";
export const CBT_GUESTS_FETCHED = "CBT_GUESTS_FETCHED";
export const CBT_TRAVEL_REASONS_FETCHED = "CBT_TRAVEL_REASONS_FETCHED";
export const CBT_CORPORATE_FETCHED = "CBT_CORPORATE_FETCHED";
export const CBT_INCREMENT_GUESTS = "CBT_INCREMENT_GUESTS";
export const CBT_DECREMENT_GUESTS = "CBT_DECREMENT_GUESTS";
export const CBT_CHANGE_FLIGHT_CLASS = "CBT_CHANGE_FLIGHT_CLASS";
export const CBT_CHANGE_COST_CENTER = "CBT_CHANGE_COST_CENTER";
export const CBT_CHANGE_TRAVEL_REASON = "CBT_CHANGE_TRAVEL_REASON";
export const CBT_ADD_TRAVELLER = "CBT_ADD_TRAVELLER";
export const CBT_REMOVE_TRAVELLER = "CBT_REMOVE_TRAVELLER";
export const CBT_INIT_FROM_URL = "CBT_INIT_FROM_URL";
export const CBT_PASSENGER_FORM_CLEAR_ERRORS =
  "CBT_PASSENGER_FORM_CLEAR_ERRORS";
export const CBT_CLOSE_PASSENGERS_FORM = "CBT_CLOSE_PASSENGERS_FORM";
export const SET_CBT_VALUE = "SET_CBT_VALUE";
export const SET_CBT_POLICY_RESOLVED_ID = "SET_CBT_POLICY_RESOLVED_ID";

// price
export const SET_FLIGHT_PRICE = "SET_FLIGHT_PRICE";
export const SET_ALTERNATIVE_CURRENCY_FLIGHT_PRICE =
  "SET_ALTERNATIVE_CURRENCY_FLIGHT_PRICE";
export const SET_PASSENGERS_PRICE = "SET_PASSENGERS_PRICE";
export const SET_ALTERNATIVE_CURRENCY_PASSENGERS_PRICE =
  "SET_ALTERNATIVE_CURRENCY_PASSENGERS_PRICE";
export const SET_ADDITIONAL_SERVICE_PRICE = "SET_ADDITIONAL_SERVICE_PRICE";
export const SET_ALTERNATIVE_CURRENCY_ADDITIONAL_SERVICE_PRICE =
  "SET_ALTERNATIVE_CURRENCY_ADDITIONAL_SERVICE_PRICE";
export const REMOVE_ADDITIONAL_SERVICE = "REMOVE_ADDITIONAL_SERVICE";
export const GET_FINAL_PRICE = "GET_FINAL_PRICE";
export const RESET_PRICE_STATE = "RESET_PRICE_STATE";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_PAYMENT_PRICE = "SET_PAYMENT_PRICE";
export const SET_ALTERNATIVE_CURRENCY_PAYMENT_PRICE =
  "SET_ALTERNATIVE_CURRENCY_PAYMENT_PRICE";

// hotels
export const SET_HOTELS_VALUE = "SET_HOTELS_VALUE";
export const SET_HOTEL_ROOMS_VALUE = "SET_HOTEL_ROOMS_VALUE";

// empty action
export const NULL_ACTION = "NULL_ACTION";

// message center
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_VIEWED_MESSAGE_STATUS = "SET_VIEWED_MESSAGE_STATUS";
export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";
