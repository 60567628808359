import { getCookie } from "shared/data/cookiesOps";
import { SearchQuery } from "shared/data/reducers/types";

type PageType =
  | "results"
  | "home"
  | "bestOffers"
  | "bestOfferResults"
  | "purchase"
  | "customerInfo"
  | "services"
  | "other";

type EventType =
  | "page"
  | "search"
  | "purchase"
  | "view_promotion"
  | "begin_checkout"
  | "add_payment_info";

export const pageDataLayer = (
  pageType: PageType,
  user: { username?: string; email?: string }
) => {
  const payload = {
    page_type: pageType,
    user_id: user?.username || "",
    user_email: user?.email || "",
  };
  pushToDataLayer("page", payload);
};

export const searchDataLayer = (searchQuery: SearchQuery) => {
  const payload = {
    search: {
      term: `${searchQuery?.from || ""}_${searchQuery?.to || ""}`,
      tolerance: Number(searchQuery?.toleranceDays || "0"),
      airlines: `${searchQuery?.preferred_airline || ""}`,
      type: `${searchQuery?.max_transfers === "direct" ? "direct" : "all"}`,
    },
  };
  pushToDataLayer("search", payload);
};

export const purchaseDataLayer = (bookingData, intl) => {
  const isDiscounted = bookingData?.reservationsDetails?.some(
    (reservation) =>
      reservation?.ServicePrices?.ServicePrice.filter(
        (servicePrice) => servicePrice?.Service === "discount"
      )?.length > 0
  );

  const flightTypes = {
    ONE_WAY: {
      name: intl.formatMessage({ id: "Constant.oneWay" }),
      id: intl.formatMessage({ id: "Constant.oneWayCode" }),
    },
    RETURN: {
      name: intl.formatMessage({ id: "Constant.return" }),
      id: intl.formatMessage({ id: "Constant.returnCode" }),
    },
    MULTICITY: {
      name: intl.formatMessage({ id: "Constant.multicity" }),
      id: intl.formatMessage({ id: "Constant.multicityCode" }),
    },
    TWO_ONE_WAY: {
      name: intl.formatMessage({ id: "Constant.twoOneWay" }),
      id: intl.formatMessage({ id: "Constant.twoOneWayCode" }),
    },
  };

  const flightType = () => {
    if (bookingData?.reservationsIds?.length === 1) {
      if (
        bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
          ?.length === 1
      ) {
        return flightTypes.ONE_WAY;
      }

      if (
        bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
          ?.length === 2
      ) {
        return flightTypes.RETURN;
      }

      if (
        bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
          ?.length > 2
      ) {
        return flightTypes.MULTICITY;
      }
    }

    if (bookingData?.reservationsIds?.length === 2) {
      return flightTypes.TWO_ONE_WAY;
    }
  };

  const discount =
    Math.abs(
      bookingData?.reservationsDetails[0].ServicePrices?.ServicePrice?.find(
        (service) => service.Service === "discount"
      )?.Price || 0
    ) || "";

  bookingData?.reservationsIds?.map((id, index) => {
    const flightData = bookingData?.reservationsDetails?.find(
      (reservation) => reservation?.ReservationId === id
    );

    const flights = flightData?.FlightItinerary?.FlightStream?.flatMap(
      (flightStream) =>
        flightStream.FlightOption?.flatMap((flightOption) =>
          flightOption.FlightSegments?.FlightSegment?.map((flightSegment) => ({
            item_id:
              flightSegment?.MarketingAirline + flightSegment?.FlightNumber,
            item_name: `${flightSegment?.OriginAirport}_${flightSegment?.DestinationAirport}`,
            coupon: getCookie("promoCode") ?? "",
            discount,
            currency: bookingData?.currency,
            index,
            item_list_id: flightType()?.id,
            item_list_name: flightType()?.name,
            item_brand: flightSegment?.MarketingAirline,
            price: bookingData?.fullPrices[index],
            quantity: 1,
          }))
        )
    );

    const payload = {
      transaction_id: id,
      value: bookingData?.fullPrice,
      currency: bookingData?.currency,
      discount,
      shipping: 0,
      tax: 0,
      coupon: getCookie("promoCode") ?? "",
      ...(bookingData?.promoCode && isDiscounted
        ? { discount: bookingData.promoCode }
        : {}),
      items: flights,
    };

    return pushToDataLayer("purchase", { ecommerce: payload });
  });
};

export const viewPromotionDataLayer = () => {
  pushToDataLayer("view_promotion");
};

export const beginCheckoutDataLayer = () => {
  pushToDataLayer("begin_checkout");
};

export const addPaymentInfo = () => {
  pushToDataLayer("add_payment_info");
};

const pushToDataLayer = (event: EventType, payload?: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...payload,
  });
};
